import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import {
  Button,
  Stack,
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Heading,
  Text,
  Textarea,
  theme,
} from '@chakra-ui/core';
import {
  Formik,
  Form,
  Field,
} from 'formik';

import {
  validateEmail,
  validateTextNoSpecial,
  validateText,
} from '../components/logic/formvalidator';
import Layout from '../components/display/layout';
import SEO from '../components/seo';
import ImageCard from '../components/display/imagecard';

import { buttonColor } from '../components/display/constants';
import { ContactText } from '../components/textfiles/contenttext';
import { FormText, Navigation } from '../components/textfiles/maintexts';


class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(values, setSubmitting) {
    const { formFinished } = this.props;
    return setTimeout(() => {
      // alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
      formFinished(values);
    }, 400);
  }

  render() {
    const honeyStyle = {
      opacity: 0,
      top: 0,
      left: 0,
      height: 0,
      width: 0,
      zIndex: -1,
    };
    return (
      <Formik
        initialValues={{ name: '', email: '', surname: '' }}
        onSubmit={(values, { setSubmitting }) => this.submitForm(values, setSubmitting)}
      >
        {(props) => (
          <Form style={{ width: '90vw', maxWidth: '650px' }}>
            <Grid gap={3}>
              <Box>
                <Field name="surname">
                  {({ field }) => (
                    <FormControl style={honeyStyle }>
                      <FormLabel htmlFor="surname" style={honeyStyle}>
                        {FormText.surname.label}
                      </FormLabel>
                      <Input
                        {...field}
                        id="surname"
                        autocomplete="off"
                        placeholder={FormText.surname.helper}
                        style={honeyStyle}
                      />
                    </FormControl>
                  )}
                </Field>
                <Field name="name" validate={(val) => validateTextNoSpecial(val, true)}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.touched.name && form.errors.name} isRequired>
                      <FormLabel htmlFor="name">
                        {FormText.name.label}
                      </FormLabel>
                      <Input {...field} id="name" placeholder={FormText.name.helper} />
                      <FormErrorMessage>
                        {form.errors.name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email" validate={(val) => validateEmail(val, true)}>
                  {({ field, form }) => (
                    <FormControl isInvalid={form.touched.email && form.errors.email} isRequired>
                      <FormLabel htmlFor="email">
                        {FormText.email.label}
                      </FormLabel>
                      <Input {...field} id="email" placeholder={FormText.email.helper} />
                      <FormErrorMessage>
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Button
                  mt={4}
                  variantColor={buttonColor}
                  isLoading={props.isSubmitting}
                  type="submit"
                >
                  {FormText.submit}
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    );
  }
}
ContactForm.propTypes = {
  formFinished: PropTypes.func.isRequired,
};

class FormHandler extends React.Component {
  constructor(props) {
    super(props);

    this.formFinished = this.formFinished.bind(this);
    this.state = {
      finished: false,
    };
  }

  componentDidMount() {
    this.setState({ finished: false });
  }

  formFinished(values) {
  console.log("FormHandler -> formFinished -> values", values)
    this.setState({ finished: true });
  }

  render() {
    const { finished } = this.state;
    return (
      <>
        <Stack spacing={8} align="center">
          <Text p={3} fontSize="lg">
            {finished ? ContactText.thanks : ContactText.contact}
          </Text>
        </Stack>
        {finished ? (
          <>
            <Stack spacing={8} align="center">
              <Link to="/">
                <Button w={150} m={1} variantColor={buttonColor}>
                  {Navigation.goBack}
                </Button>
              </Link>
            </Stack>
            <Stack spacing={8} align="center">
              <ImageCard image={{ name: 'astronaut' }} width={300} style={{ margin: '6px' }} />
            </Stack>
          </>
        ) : (
          <Stack spacing={8} align="center">
            <ContactForm formFinished={this.formFinished} />
          </Stack>
        )}
      </>
    );
  }
}

const ContactPage = () => (
  <Layout>
    <SEO title={ContactText.title} />
    <Heading
      as="h1"
      size="lg"
      m={1}
      style={{ color: theme.colors.purple[500] }}
    >
      {ContactText.title}
    </Heading>
    <FormHandler />
  </Layout>
);

export default ContactPage;
